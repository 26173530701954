import { useEffect, useState } from "react";
import "./style.scss";
import { Col, Row } from "react-bootstrap";
import { mobileWidth } from "../../../utils";
import cryptoSluggers from "../../../assets/crypto-sluggers.png";
import dingerV2 from "../../../assets/DingerSqauad Logo_Ver2.png";
import cryptocom from "../../../assets/crypto-com.png";
import { BsDash } from "react-icons/bs";
import { AiOutlineDash, AiOutlineArrowRight } from "react-icons/ai";

const TheSquad = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div className="thesquad" id="squad">
      <img
        src={
          "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094601/Dinger/l4pxsn0e5zik85rkpplp.png"
        }
        alt=""
        style={{
          position: "absolute",
          top: isMobile ? "-53px" : "-70px",
          left: isMobile ? "11%" : "40%",
          width: isMobile ? "280px" : "350px",
          zIndex: "25",
        }}
      />

      <Row className="d-flex">
        <Col lg={12} className="mt-4 mt-md-0">
          <img
            className="squad"
            src={
              "https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,w_961/v1654368118/Dinger/dwrnitnr6fabtps4d1fu.png"
            }
          />
        </Col>
        <Col lg={12} className="left mt-4">
          <div>
            <p
              style={{
                textAlign: "center",
                fontSize: isMobile ? "18px" : "24px",
                marginTop: "0px",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: isMobile ? "36px" : "48px",
                    lineHeight: isMobile ? "1.02" : "",
                  }}
                >
                  Crypto Sluggers has partnered with Crypto.com to launch Dinger
                  Squad NFT Collection.
                </span>
                <br></br>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: isMobile ? "column" : "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    padding: "0px 50px",
                  }}
                >
                  <img src={cryptoSluggers} alt="" width={250} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "40px",
                      margin: isMobile ? "25px 0" : "",
                    }}
                  >
                    +
                  </div>
                  <img src={dingerV2} alt="" width={250} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "40px",
                      margin: isMobile ? "25px 0" : "",
                    }}
                  >
                    +
                  </div>{" "}
                  <img src={cryptocom} alt="" width={250} />
                </div>
                <br></br>
                <span
                  style={{
                    fontSize: isMobile ? "30px" : "36px",
                    lineHeight: isMobile ? "1.03" : "",
                  }}
                >
                  Get whitelisted to mint on Crypto.com NFT Marketplace at
                  pre-sale pricing.
                </span>
                <br></br>
                <br></br>
                <span style={{ fontSize: isMobile ? "30px" : "36px" }}>
                  How does it work?
                </span>
                <br></br>1. Follow our{" "}
                <a
                  href={"https://twitter.com/squad_dinger"}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#a62f24" }}
                >
                  Twitter
                </a>
                <br></br>2. Follow our{" "}
                <a
                  href={"https://www.instagram.com/squaddinger/"}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#a62f24" }}
                >
                  Instagram
                </a>
                <br></br>3. Complete the Crypto.com{" "}
                <a
                  href={
                    "https://docs.google.com/forms/d/e/1FAIpQLSeQHR0jPbFHcuacmemcGGaJR-iSZrfJsZ9vWBUCMu0zW5AReQ/viewform"
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#a62f24" }}
                >
                  Whitelist form
                </a>
              </b>{" "}
              <br></br>
              <p
                style={{
                  marginTop: "25px",
                  marginBottom: "0",
                  textAlign: "center",
                }}
              >
                <b>Crypto.com NFT Marketplace</b> PRE-SALE MINTING Date TBD.
                Stay updated on our{" "}
                <a
                  href={"https://discord.com/invite/FTFvAVEp"}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#a62f24" }}
                >
                  Discord
                </a>{" "}
                and{" "}
                <a
                  href={"https://twitter.com/squad_dinger"}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#a62f24" }}
                >
                  Twitter
                </a>{" "}
                <br></br>
                <br></br>
                Dinger Squad NFTs are <b>9 collections</b> at{" "}
                <b>1,111 variations each. Crypto.com</b> will reveal 444 each of
                the 9 collections for a total of <b>3,996 “surprise packs”</b>.
                Reveal will include Smokey &bull; Specs &bull; Chulo &bull;
                Shorty &bull; Tiny &bull; Fireball &bull; Coach &bull; Bolt
                &bull; Revolt.
              </p>
            </p>

            <p
              style={{
                textAlign: "start",
                paddingInlineStart: isMobile ? "0" : "25px",
              }}
            >
              <b>Public Minting</b> will reveal remaining{" "}
              <b>6,003 Dinger Squad collection </b>
              to be minted on Dinger Squad website.
              <b> Public ”LIVE MINT” to be announced</b> on our Discord and
              Twitter. All public minted NFTs will be displayed in our gallery
              and OpenSea Marketplace.
              <br></br>
            </p>
          </div>
          <div style={{ paddingInline: isMobile ? "" : "25px" }}>
            <p
              style={{
                fontSize: isMobile ? "36px" : "42px",
                fontWeight: "bold",
                marginTop: "35px",
                textAlign: "center",
              }}
            >
              Who is the squad?
            </p>
            <p>
              <b>Dinger Squad Legends</b> puts baseball back into America’s past
              time and awaken the kid in all of us.{" "}
            </p>
            <p>
              With players such as <b>Fireball</b>, a pitcher who throws over{" "}
              <b>125mph</b>, and leads the league in <b>most shutouts (50)</b>{" "}
              in a single season. <b>Bolt</b>, the fastest outfielder in
              baseball with running speed of 35.9(feet/second). <b>Smokey</b>, a
              first basemen with the most powerful hitting who shattered the
              single season homerun record <b>at 99 HRs</b> and{" "}
              <b>the longest home run ever hit at 759 feet.</b> <b>Revolt</b>,
              the <b>first female pitcher</b> to make it to the SHOW. She has{" "}
              <b>9 different combination pitches</b> and opponents are not able
              to keep up. She <b>empowers women</b> during off-season through
              various
              <b> sports</b> and <b>women-owned organizations</b> globally.
            </p>
            <p>
              {" "}
              These are just a few of our
              <b> 9 ball players. 9 collections.</b> Each with{" "}
              <b>1,111 rare traits</b> at total of <b>9,999 NFTs</b> ready to be
              minted by you.{" "}
            </p>
            <p>
              Dinger Squad is unique and programmatically generated from over{" "}
              <b>300 possible traits</b>, including expression, headwear,
              clothing, and more.{" "}
            </p>
            <p>
              The Squad NFTs are developed as
              <b> ERC-721A</b> tokens on the Ethereum blockchain.{" "}
              <b>(A) meaning Azuki</b>, which is an improved mint function with
              significant gas savings. Minting multiple NFTs in a single
              transaction. Our Squad NFTs are hosted on IPFS Pinata.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TheSquad;
