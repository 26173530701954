import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/DingerLogo.png";
import { mobileWidth } from "../../utils";
import { connectWallet } from "../Modals/ConnectWalletPopup";
import WhitePaper from "../Modals/Whitepaper";

import "./style.scss";

const Header = () => {
  const [isMobile, setisMobile] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [showWhitepaper, setShowWhitepaper] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  const onConnect = async () => {
    const acc = await connectWallet();

    if (acc) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="header"
      style={{
        paddingInline: isMobile ? "0" : "3=5px",
        background: "rgba(0,0,0,0.68)",
        position: "fixed",
        width: "100%",
        zIndex: "1000",
      }}
    >
      <Navbar.Brand href="/">
        <img
          src={logo}
          width={isMobile ? "80" : "130"}
          height={isMobile ? "40" : "50"}
          className="d-inline-block align-top"
          alt="Dinger logo"
          style={{ marginLeft: isMobile ? "15px" : "" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle style={{ background: "#ddcba3" }} />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-between"
      >
        <Nav.Link href="/#mint">Mint</Nav.Link>
        <Nav.Link href="/#about">About</Nav.Link>
        <Nav.Link href="/#squad">Squad</Nav.Link>
        <Nav.Link href="/#team">Team</Nav.Link>
        <Nav.Link href="/#roadmap">Roadmap</Nav.Link>
        <Nav.Link href="/#faq">FAQ</Nav.Link>
        <Nav.Link onClick={()=> setShowWhitepaper(true)}>WhitePaper</Nav.Link>
        <Button
          style={{ backgroundColor: "#a62f24", border: "none" }}
          onClick={() => onConnect()}
          className="connect"
        >
          {isConnected ? "Connected" : "Connect Wallet"}
        </Button>
      </Navbar.Collapse>
      <WhitePaper showModal={showWhitepaper} />
    </Navbar>
  );
};

export default Header;
