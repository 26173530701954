import { useEffect, useState } from "react";
import "./style.scss";
import RevealCardList from "../RevealCardList";
import { mobileWidth } from "../../../utils";

const Reveal = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div className="reveal" id="reveal">
      <img
        src={
          "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094598/Dinger/wtq2xkgeoguejfjyrzvs.png"
        }
        alt=""
        style={{
          position: "absolute",
          top: isMobile ? "-53px" : "-70px",
          left: isMobile ? "15%" : "40%",
          width: isMobile ? "280px" : "350px",
        }}
      />
      <div>
        <RevealCardList
          width={isMobile ? 300 : 450}
          height={isMobile ? 346 : 450}
        />
      </div>
    </div>
  );
};

export default Reveal;
