import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import "./style.scss";
import {
  getMetamaskProvider,
  mintNFTAbi,
  mintNFTAddress,
} from "../../../contracts/contract";
import {
  ABI,
  collectionDates,
  CollectionName,
  mobileWidth,
  smallLapWidth,
} from "../../../utils";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { connectWallet, web3 } from "../../Modals/ConnectWalletPopup";
import Web3 from "web3";
import { connect } from "http2";

const MintCard = () => {
  const MaxQuantity = 15;
  const zoomOutProperties = {
    duration: 1000,
    transitionDuration: 10,
    infinite: true,
    indicators: false,
    scale: 1,
    arrows: false,
  };

  const [selectedIndex, setSelectedIndex] = useState(8);
  const [nftPrice, setNftPrice] = useState(0.09);
  const [nftRemaining, setNftRemaining] = useState(400);
  const [quantity, setQuantity] = useState(1);
  const [isMobile, setisMobile] = useState(false);
  const [isSmallLap, setisSmallLap] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showMint, setShowMint] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const width = 600;
  const height = 600;
  const collection = [
    `https://drive.google.com/thumbnail?id=1YMR_MXxLonrqVfuMBmN5aXS-buaebdhf&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1NKzrP2DcwpofK2QnSiBH0A29cNlH5FPY&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1v49eMQuDp0yW_mCcJ3vbTog117WCm-VP&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1qX2kIBmCs9k60wPFUQKJuyHnvhnJq-ND&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1uzjdppFq6QiLj-uvbW4m4t1IfSQuKFDK&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1uJ6Ldc8wHmhqlEeFjsiAIp27Al5zdicU&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=17slhC3Xk1vfpiGkH8pCmO4vmcij7tv0Y&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1-muSEKD245BxGlnFPSQkUDECNwrA7PyN&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1mENchfib7qyR9f1G4u_VRRAQsuM1z6_H&sz=w${width}-h${height}`,
  ];

  const onConnect = async () => {
    const acc = await connectWallet();

    if (acc) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  };
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
    if(width > mobileWidth && width<= smallLapWidth){
      setisSmallLap(true)
    }else{
        setisSmallLap(false);
    }
  };
  const countdownRenderer = ({ formatted, completed }) => {
    if (completed) {
      setShowMint(true);
      // Render a completed state
      return (
        <div>
          <p
            className="countdown m-0"
            style={{
              textAlign: "start",
              color: "black",
              fontFamily: "Britannic Bold",
              fontSize: isMobile ? "18px" : "24px",
              fontWeight: "600",
              margin: isMobile ? "0 55px" : "",
            }}
          >
            {" "}
            "Minting soon" Pre-Sale minting to be launched on Crypto.com NFT
            Marketplace. Whitelist in progress!
          </p>
          <p
            className="timer mb-0 invisible"
            style={{
              textAlign: isMobile ? "center" : "start",
              color: "#a62f24",
              fontFamily: "Book",
              fontWeight: "bold",
              fontSize: isMobile ? "44px" : "70px",
            }}
          >
            {formatted.days}:{formatted.hours}:{formatted.minutes}:
            {formatted.seconds}
          </p>
          <span
            className="timer-info d-flex justify-content-between invisible"
            style={{
              marginRight: isMobile ? "" : "100px",
              marginLeft: isMobile ? "10px" : "25px",
            }}
          >
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Days
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Hours
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Minutes
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Seconds
            </p>
          </span>
        </div>
      );
    } else {
      // Render a countdown
      setShowMint(false);
      return (
        <div>
          <p
            className="countdown m-0"
            style={{
              textAlign: "start",
              color: "black",
              fontFamily: "Britannic Bold",
              fontSize: isMobile ? "18px" : "24px",
              fontWeight: "600",
              margin: isMobile ? "0 55px" : "",
            }}
          >
            {" "}
            Pre Sale starting in
          </p>
          <p
            className="timer mb-0"
            style={{
              textAlign: isMobile ? "center" : "start",
              color: "#a62f24",
              fontFamily: "Book",
              fontWeight: "bold",
              fontSize: isMobile ? "44px" : "70px",
            }}
          >
            {formatted.days}:{formatted.hours}:{formatted.minutes}:
            {formatted.seconds}
          </p>
          <span
            className="timer-info d-flex justify-content-between"
            style={{
              marginRight: isMobile ? "" : "100px",
              marginLeft: isMobile ? "10px" : "25px",
            }}
          >
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Days
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Hours
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Minutes
            </p>
            <p
              style={{
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Seconds
            </p>
          </span>
        </div>
      );
    }
  };

  const getEthPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const ethUsd = res.data.ethereum.usd;
      setNftPrice(parseFloat((180 / ethUsd).toFixed(4)));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getMetamaskProvider();
    updateDimensions();
    // getEthPrice();
    window.addEventListener("storage", () => {
      const index = localStorage.getItem("selectedCollection");
      setSelectedIndex(parseInt(index));
    });
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    async function newFunction() {
      try {
        setNftRemaining(400);
        let newWeb3;
        try {
          newWeb3 = new Web3(
            "https://speedy-nodes-nyc.moralis.io/362fc40c1ab324c15e79d4da/eth/mainnet"
          );
        } catch (err) {
          setLoading(false);
          setNftRemaining(400);
          toast.error("Please connect a wallet to mint");
          return;
        }

        const contract = new newWeb3.eth.Contract(
          ABI,
          CollectionName[selectedIndex].contractAddress
        );

        contract.methods
          .totalSupply()
          .call()
          .then((val) => {
            setNftRemaining(400 - val);
          });
      } catch (err) {
        console.log(err);
        setNftRemaining(400);
      }
    }
    newFunction();
  }, [selectedIndex]);

  const mintNFT = async () => {
    toast("Mint soon");
    return;
    try {
      if (!showMint && selectedIndex != 8) {
        toast(
          "Mint will be available soon. Please check Whitelist details in meantime."
        );

        return;
      }
      if (nftRemaining == 0) {
        toast("All NFTs are sold out.");
        return;
      }
      if (quantity == 0) {
        toast.error("Quantity should atleast be 1");
        return;
      }

      setLoading(true);
      if (!web3) {
        try {
          const res = await connectWallet();
          console.log("res", res);
        } catch (err) {
          setLoading(false);
          toast.error("Please connect a wallet to mint");
          return;
        }
      }
      console.log("address", CollectionName[selectedIndex].contractAddress);

      const contract = new web3.eth.Contract(
        ABI,
        CollectionName[selectedIndex].contractAddress
      );
      await contract.methods
        .mintNft(quantity)
        .send({
          from: web3.eth.currentProvider.selectedAddress,
          value: (quantity * 7 * 10 ** 16).toString(),
        })
        .then((val) => {
          console.log("mint", val);

          window.open(
            `https://opensea.io/${web3.eth.currentProvider.selectedAddress}`
          );
          if (val) {
            toast.success("NFT minted successfully");
          } else {
            toast.error("Error while minting.");
          }
        })
        .catch((err) => {
          console.log(err);

          toast.error("Error while minting.");
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Error while minting.");
    }
    setLoading(false);
  };

  return (
    <div className="mintCard d-flex">
      <div className="mint-left paper d-flex p-2">
        {/* <img src={bannerNft} alt="" width={300} height={420} /> */}
        <Zoom
          {...zoomOutProperties}
          style={{
            width: isMobile ? "338px" : "368px",
            height: "50%",
            marginTop: "25px",
          }}
        >
          {collection.map((fadeImage, index) => (
            <div className="each-fade" key={index}>
              <div className="image-container">
                <img
                  src={fadeImage}
                  width={isMobile ? 338 : 328}
                  height={isMobile ? 380 : 383}
                />
              </div>
            </div>
          ))}
        </Zoom>
        <div style={{ marginInline: "35px", marginTop:"15px" }}>
          <p className="title" style={{ borderBottom: "3px dotted black" }}>
            PUBLIC MINT SOON
          </p>
          <div style={{}}>
            <div style={{ borderBottom: "3px dotted black" }}>
              {/* <Countdown
              date={collectionDates[selectedIndex]}
              zeroPadTime={2}
              renderer={countdownRenderer}
              
            /> */}
              <p
                className="countdown m-0"
                style={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Britannic Bold",
                  fontSize: isMobile ? "18px" : "24px",
                  fontWeight: "600",
                  margin: isMobile ? "0" : "",
                  padding:isMobile?"25px 0": isSmallLap? "10px 0":"35px 150px 35px 0px"
                }}
              >
                {" "}
                Pre-Sale minting to be launched on Crypto.com NFT Marketplace.
                Whitelist in progress!
              </p>
            </div>
            <div style={{ marginRight: isMobile ? "" : "150px" }}>
              <div
                className={`d-flex align-items-center text-center mt-3 ${
                  isMobile ? "justify-content-between" : ""
                }`}
              >
                <p
                  style={{
                    color: "black",
                    fontFamily: "Britannic Bold",
                    fontSize: isMobile ? "16px" : "18px",
                    fontWeight: "600",
                  }}
                >
                  Price per NFT
                </p>
                <p
                  style={{
                    color: "black",
                    fontFamily: "Britannic Bold",
                    fontSize: isMobile ? "16px" : "26px",
                    fontWeight: "600",
                    marginLeft: "15px",
                  }}
                >
                  {nftPrice} ETH
                </p>
              </div>
              <div
                className={`d-flex align-items-center text-center ${
                  isMobile ? "justify-content-between" : ""
                }`}
              >
                <p
                  style={{
                    color: "black",
                    fontFamily: "Britannic Bold",
                    fontSize: isMobile ? "16px" : "18px",
                    fontWeight: "600",
                  }}
                >
                  Remaining
                </p>
                <p
                  style={{
                    textAlign: "start",
                    color: "black",
                    fontFamily: "Britannic Bold",
                    fontSize: isMobile ? "16px" : "26px",
                    fontWeight: "600",
                    marginLeft: "55px",
                  }}
                >
                  {/* {nftRemaining} */}
                  TBD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="mint-right py-3 px-3">
        {/* <img className="barcode" src={barcode} alt="" /> */}
        <QRCodeSVG
          value={`https://etherscan.io/address/${CollectionName[selectedIndex].contractAddress}`}
          bgColor="transparent"
          size={200}
        />
        <p className="m-0" style={{ fontWeight: "600" }}>
          Contract QR Code
        </p>
        ,
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ marginTop: isMobile ? "15px" : "" }}
        >
          <p
            style={{
              color: "black",
              fontFamily: "Britannic Bold",
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: "600",
            }}
          >
            Select Quantity
          </p>
          <div className="d-flex">
            <p
              style={{
                cursor: "pointer",
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: "600",
              }}
              onClick={() =>
                quantity > 0 ? setQuantity(quantity - 1) : setQuantity(quantity)
              }
            >
              -
            </p>
            <p
              className="px-4"
              style={{
                color: "#a62f24",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: "600",
              }}
            >
              {quantity}
            </p>
            <p
              style={{
                cursor: "pointer",
                color: "black",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: "600",
              }}
              onClick={() =>
                quantity < MaxQuantity
                  ? setQuantity(quantity + 1)
                  : (setQuantity(quantity),
                    toast.error(`You can't buy more than 15`))
              }
            >
              +
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p
            style={{
              color: "black",
              fontFamily: "Britannic Bold",
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: "600",
            }}
          >
            Total
          </p>
          <p
            style={{
              color: "#a62f24",
              fontFamily: "Britannic Bold",
              fontSize: isMobile ? "18px" : "24px",
              fontWeight: "600",
            }}
          >
            {parseFloat((quantity * nftPrice).toFixed(2))} ETH
          </p>
        </div>
        {true ? (
          <Button onClick={() => mintNFT()} disabled>
            Mint Soon
          </Button>
        ) : (
          <Button onClick={mintNFT} disabled={nftRemaining == 0}>
            {isLoading ? (
              <Spinner animation="border" />
            ) : nftRemaining == 0 ? (
              "Sold Out"
            ) : (
              "Mint"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default MintCard;
