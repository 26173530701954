import { useEffect, useState } from "react";
import SocialWidget from "../../SocialWidget";
import { mobileWidth } from "../../../utils";
import "./style.scss";

const Banner = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  const countdownRenderer = ({ formatted, completed }) => {
    if (false) {
      // Render a completed state
      return <span style={{ color: "white" }}>You are good to go!</span>;
    } else {
      // Render a countdown
      return (
        <div className={`${isMobile ? "" : "ms-5"}`}>
          <p
            className="countdown"
            style={{
              color: "white",
              fontFamily: "Britannic Bold",
              fontSize: isMobile ? "34px" : "50px",
              fontWeight: "600",
              background: isMobile ? "rgba(0,0,0,0.68)" : "",
              margin: isMobile ? "0 55px" : "",
            }}
          >
            {" "}
            Presale Countdown
          </p>
          <span
            className="timer"
            style={{
              color: "#ddcba3",
              fontSize: isMobile ? "44px" : "80px",
              background: "rgba(0,0,0,0.68)",
              padding: "0 25px",
            }}
          >
            {formatted.days}:{formatted.hours}:{formatted.minutes}:
            {formatted.seconds}
          </span>
          <span
            className="timer-info d-flex justify-content-evenly"
            style={{
              marginRight: isMobile ? "" : "120px",
              marginLeft: isMobile ? "25px" : "",
            }}
          >
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "24px",
                fontWeight: "600",
              }}
            >
              Days
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "24px",
                fontWeight: "600",
              }}
            >
              Hours
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "24px",
                fontWeight: "600",
              }}
            >
              Minutes
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "24px",
                fontWeight: "600",
              }}
            >
              Seconds
            </p>
          </span>
        </div>
      );
    }
  };
  return (
    <div className="banner" id="home">
      {/* {isMobile ? (
        <Row style={{ height: "100%" }}>
          <img
            src={bannerNft}
            alt=""
            style={{
              width: "80%",
              position: "relative",
              top: "20%",
              margin: "auto",
              zIndex: "10",
            }}
          />
          <div style={{ zIndex: "11" }}>
            <Countdown
              date={Date.now() + 500000000}
              zeroPadTime={2}
              renderer={countdownRenderer}
            />
          </div>
        </Row>
      ) : (
        <Row style={{ height: "100%" }}>
          <img
            src={bannerNft}
            alt=""
            style={{
              width: "22%",
              height: "55%",
              margin: "auto",
              left:"40%",
              top:"40%",
              position: "absolute",
            }}
          />
          <Col
            lg={6}
            className="d-flex flex-column justify-content-center text-start h-100 pb-5"
            style={{marginTop:"35px"}}
          >
            <Countdown
              date={Date.now() + 500000000}
              zeroPadTime={2}
              renderer={countdownRenderer}
            />
            ,
          </Col>
          */}

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          WebkitJustifyContent:"flex-end",
          WebkitAlignItems:"flex-end",
          justifyContent:isMobile?"center": "end",
          height: "100%",
          paddingBottom: isMobile ? "45px" : "",
          width: isMobile ? "100%" : "",
        }}
      >
        <SocialWidget
          color={"#fff"}
          isRow={isMobile ? true : false}
          size={40}
          sizeFb={31}
        />
      </div>

      {/* // )}  */}
    </div>
  );
};

export default Banner;
