import { useEffect, useRef, useState } from "react";
import { mobileWidth } from "../../../utils";

import "./style.scss";
import { Row, Col } from "react-bootstrap";

const TeamCardList = ({ width, height }) => {
  const scale = 2.5;
  const array = [
    `https://drive.google.com/thumbnail?id=1bjBM8pWF09KUcSyVwZwhSY0hx8CbDzGX&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=14owb-8033uMVmX-z5Ro2ZJslMEM85cJX&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=12EjmlUL_GGNOhdgWqk8eWw_SXoqwJFt2&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=1rxRGzoMGvULGlDMRRx1De1CrZW5iigw-&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=1Av_T4ku5jrWRwCjJfoDUoV6kV4YBV9lO&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=1GPL2cszQ3dsYUGxLeef4W7kJxFSxRW1O&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=1EzcL7XnyKaMsyqRaOwT5c0kLav0Le3BY&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=1b9rb3N9Eo31P2Nhg4ubdFJgguaQ3t-BT&sz=w${
      width * scale
    }-h${height * scale}`,
    `https://drive.google.com/thumbnail?id=1m8avkai4XAOGSyeBwRS3lDdrGFJMNIwu&sz=w${
      width * scale
    }-h${height * scale}`,
  ];

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  return (
    <div className="d-flex align-items-center px-0 px-md-3  h-100">
      <Row>
        <Col lg={12} className="teamcardlist d-flex" ref={ref}>
          {array.map((item, i) => (
            <div className="cont mt-3 mx-0 mx-md-4">
              <img
                className="image"
                src={item}
                alt=""
                key={i}
                width={width}
                height={height}
                style={{ objectFit: "contain" }}
              />
              {/* <div className="middle">
                <p className="text">{teamSpecs[i].name}</p>
                <p className="text">{teamSpecs[i].role}</p>
                <p className="text">{teamSpecs[i].edu}</p>
                <p className="text">{teamSpecs[i].highlight[0]}</p>
              </div> */}
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default TeamCardList;
