import React, { useEffect, useRef, useState } from "react";
import { HashLink } from 'react-router-hash-link';
import arrowLeft from "../../../assets/arrow-left.png";
import arrowRight from "../../../assets/arrow-right.png";
import { collectionDates, CollectionName, mobileWidth } from "../../../utils";
import { useHistory } from "react-router-dom";


import "./style.scss";
import Countdown from "react-countdown";

const RevealCardList = ({ width, height }) => {
  const [collectionIndex, setCollectionIndex] = useState(8);
  const collection = [
    `https://drive.google.com/thumbnail?id=1YMR_MXxLonrqVfuMBmN5aXS-buaebdhf&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1NKzrP2DcwpofK2QnSiBH0A29cNlH5FPY&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1v49eMQuDp0yW_mCcJ3vbTog117WCm-VP&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1qX2kIBmCs9k60wPFUQKJuyHnvhnJq-ND&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1uzjdppFq6QiLj-uvbW4m4t1IfSQuKFDK&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1uJ6Ldc8wHmhqlEeFjsiAIp27Al5zdicU&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=17slhC3Xk1vfpiGkH8pCmO4vmcij7tv0Y&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1-muSEKD245BxGlnFPSQkUDECNwrA7PyN&sz=w${width}-h${height}`,
    `https://drive.google.com/thumbnail?id=1mENchfib7qyR9f1G4u_VRRAQsuM1z6_H&sz=w${width}-h${height}`,
  ];

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const [isMobile, setisMobile] = useState(false);
  const navigate = useHistory();
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  const countdownRenderer = ({ formatted, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span
          style={{
            width: isMobile ? "100%" : "100%",
            position: "relative",
            bottom: isMobile ? "126px" : "89px",
            left: isMobile ? "-10px" : "-10px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            color: "#ddcba3",
            fontFamily: "Fredericka the Great",
            fontSize: isMobile ? "34px" : "35px",
            fontWeight: "600",
          }}
        >
          "Minting soon"
        </span>
      );
    } else {
      // Render a countdown
      return (
        <div
          style={{
            width: isMobile ? "86.5%" : "89%",
            position: "relative",
            bottom: isMobile ? "126px" : "139px",
            background: "rgba(0,0,0,0.68)",
            left: isMobile ? "8px" : "12px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <span
            className="timer"
            style={{
              width: "100%",
              fontFamily:"Book",
              color: "#ddcba3",
              fontSize: isMobile ? "36px" : "55px",
              fontWeight:'bold',
              padding: "0 25px",
            }}
          >
            {formatted.days}:{formatted.hours}:{formatted.minutes}:
            {formatted.seconds}
          </span>
          <span
            className="timer-info d-flex justify-content-evenly"
            style={{
              marginRight: isMobile ? "" : "",
              marginLeft: isMobile ? "25px" : "20px",
            }}
          >
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Days
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Hours
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Minutes
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "Britannic Bold",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
              }}
            >
              Seconds
            </p>
          </span>
        </div>
      );
    }
  };
  const handleCollectionNameChange = (index:string) => {
    window.localStorage.setItem("selectedCollection", index);
    window.dispatchEvent(new Event("storage"));
    navigate.push("#mint")
    
  };
  return (
    <div className="d-flex align-items-center px-3">
      {isMobile ? (
        ""
      ) : (
        <img
          src={arrowLeft}
          alt="Left Arrow"
          width="40"
          height="40"
          style={{ position: "absolute", left: "10px" }}
          onClick={() => scroll(-width)}
        />
      )}

      <div className="revealcardlist d-flex" ref={ref}>
        {collection.map((item, i) => (
          <HashLink to="/#mint">
            <div>
              <img
                src={item}
                alt=""
                key={i}
                width={width}
                height={height}
                style={{
                  marginRight: "25px",
                  objectFit: isMobile ? "none" : "cover",
                }}
                onClick={() => handleCollectionNameChange(i.toString())}
                // onMouseEnter={() => {
                //  setCollectionIndex(i);
                // }}
                // onMouseLeave={() => {
                //   setCollectionIndex(-1);
                // }}
              />

              <Countdown
                date={collectionDates[i]}
                zeroPadTime={2}
                renderer={countdownRenderer}
              />
            </div>
          </HashLink>
        ))}
      </div>

      {isMobile ? (
        ""
      ) : (
        <img
          src={arrowRight}
          alt="Right Arrow"
          width="40"
          height="40"
          style={{ position: "absolute", right: "10px" }}
          onClick={() => scroll(width)}
        />
      )}
    </div>
  );
};

export default RevealCardList;
