import React from 'react'
import fb from "../../assets/fb-black.svg";
import ln from "../../assets/ln-logo-black.svg";
import ig from "../../assets/instagram-black.svg";
import tw from "../../assets/twitter-black.svg";
import ds from "../../assets/discord-black.svg";
import md from "../../assets/medium-black.svg";
import fbw from "../../assets/fb-white.svg";
import lnw from "../../assets/ln-logo-white.svg";
import igw from "../../assets/instagram-white.svg";
import tww from "../../assets/twitter-white.svg";
import dsw from "../../assets/discord-white.svg";
import mdw from "../../assets/medium-white.svg";
import "./style.scss"

const SocialWidget = ({color, isRow, size, sizeFb}) => {
  const fbLink = "https://facebook.com/dingersquadNFT";
  const twLink = "https://twitter.com/squad_dinger";
  const igLink = "https://instagram.com/squaddinger";
  const lnLink = "https://linkedin.com/company/dinger-squad-nft-collection/";
  const medium = "htps://medium.com/@DingerSquad";
  const discord = "https://discord.gg/FTFvAVEp";

  // const size = 50;
  // const sizeFb = 40;
  
  return (
    <div
      className={`d-flex ${isRow ? "flex-row" : "flex-md-column"}  flex-wrap social-logo`}
    >
      <a href={fbLink} target="_blank" rel="noreferrer">
        <img
          src={color == "#fff" ? fbw : fb}
          alt="Facebook logo"
          className="me-2 ms-2 pb-2"
          width={sizeFb}
        />
      </a>

      <a href={lnLink} target="_blank" rel="noreferrer">
        <img
          src={color == "#fff" ? lnw : ln}
          alt="LinkedIn logo"
          className={`me-3`}
          width={size}
          style={{
            paddingLeft: "15px",
          }}
        />
      </a>
      <a href={twLink} target="_blank" rel="noreferrer">
        <img
          src={color == "#fff" ? tww : tw}
          alt="Google Plus logo"
          className={`me-3 ${isRow ? "" : "mt-2"}`}
          width={size}
          style={{
            paddingLeft: "15px",
          }}
        />
      </a>
      <a href={igLink} target="_blank" rel="noreferrer">
        <img
          src={color == "#fff" ? igw : ig}
          alt="Google Plus logo"
          className={`me-3 ${isRow ? "" : "mt-2"}`}
          width={size}
          style={{
            paddingLeft: "15px",
          }}
        />
      </a>
      <a href={discord} target="_blank" rel="noreferrer">
        <img
          src={color=="#fff"?dsw:ds}
          alt="Google Plus logo"
          className={`me-3 ${isRow ? "" : "mt-2"}`}
          width={size}
          style={{
            paddingLeft: "15px",
          }}
        />
      </a>
      <a href={medium} target="_blank" rel="noreferrer">
        <img
          src={color=="#fff"?mdw:md}
          alt="Google Plus logo"
          className={`me-3 ${isRow ? "" : "mt-2"}`}
          width={size}
          style={{
            paddingLeft: "15px",
          }}
        />
      </a>
    </div>
  );
}

export default SocialWidget