import { Container, Row, Col } from "react-bootstrap";
import FaqItem from "./FaqItem";
import { v4 as uuid } from "uuid";
import { mobileWidth } from "../../../utils";
import "./style.scss";
import { useState, useEffect } from "react";

const Faq = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  const faqItems = [
    {
      id: uuid(),
      question: "What Is The Smart Contract Address?",
      answer: `Smokey - 0x21702036f0a6076C56421B04Ca41F4fF5EC99344
      Specs- 0x119Ad35819906DaEB4457e4dB632a3EC369D06BB
      Chulo-0x439bCDf89d8D40Df3c5C1B5392BE789b02eB5FA1
      Shorty-0xEa39CB2063d21A2612077B2Afae30734d925115C
      Tiny-0x9204c9bef844CF0647959B791f41f4d3c044C6D3
      Fireball- 0x2Ab57731c750bB3cE55C9Bfbd64A811654fC2a16
      Coach- 0x3abA816e8eb798336625878c6519b60caF4bC048
      Bolt-0x067c7B48a7e357529Ad4d031E0CcE7cA06c5b139
      Revolt-0x24043F6738bD40772179fb334f5289261CC7e829`,
    },
    {
      id: uuid(),
      question: "When Is The Launch?",
      answer: `We have not announced the presale or 
public launch dates yet. We are as excited 
as you are to kick this thing off, but we 
believe in making sure we have our ducks in 
a row to deliver you an exceptional 
experience. Stay updated through our
<a href="https://discord.gg/FTFvAVEp">Discord</a> and \
<a href="https://twitter.com/squad_dinger">Twitter</a> channels.`,
    },
    {
      id: uuid(),
      question: "How Do I Buy A Dinger Squad NFT?",
      answer: `There are 3 main ways to buy a Dinger Squad NFT.
    1. Get on our presale Whitelist and get to purchase before the public launch.
      2. Purchase (mint) a dinger on this website during the public launch.
      3. After the collection sells out, you will be able to purchase Dinger Squad NFT’s on OpenSea.`,
    },
    {
      id: uuid(),
      question: "How Do I Get On The Whitelist?",
      answer: `The whitelist gives you access to the private presale that 
occurs before the public launch. To get on the whitelist 
you must join our <a href="https://discord.gg/FTFvAVEp">Discord</a> Community and follow the 
whitelisting instructions posted inside.`,
    },
    {
      id: uuid(),
      question: "How Many Mintable NFT’s Are There?",
      answer: `There are a total of 9,999 unique NFT’s in the Dinger 
Squad Collection. They are broken up into 9 different base 
types, with 1,111 traits and rarity for each.`,
    },
    {
      id: uuid(),
      question: "Will There Be Other NFT Collections?",
      answer: `Yes.The Childhood Legends Collections is the initial kick-off for Dinger Squad. With partnering with Crypto Sluggers we will have access to professional baseball legends. Legends 
NFT collections that be launched during next phase, 
estimated time mid August. Each of these future collections 
will enhance and support the development of our GameFi
& Metaverse.`,
    },
  ];
  return (
    <div className="faq" id="faq">
      <div className="main">
        <img
          src={
            "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094597/Dinger/j3g25wejy8h4nli3p1wk.png"
          }
          alt=""
          style={{
            position: "absolute",
            top: isMobile ? "-184px" : "-200px",
            left: isMobile ? "15%" : "40%",
            width: isMobile ? "280px" : "350px",
          }}
        />
      </div>
      <div className="faq__container">
        <Container>
          <div className="faq__container">
            <Row
              style={{
                position: "relative",
              }}
            >
              <Col lg={6}>
                <img
                  src={`https://drive.google.com/thumbnail?id=1Rh2-zC8FtUDKaUDWi7p_NTtiG1h4pLMo&sz=w$650-h650`}
                  alt=""
                  style={{ height: "350px", width: isMobile ? "90vw" : "50%" }}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="mb-3 mt-4 mt-sm-0"
              >
                <div className="maz d-flex flex-column justify-content-between">
                  {faqItems?.map((item, i) => (
                    // <Col xl={12} key={item.id}>
                    <FaqItem
                      question={item.question}
                      content={item.answer}
                      key={item.id}
                      id={i}
                    />
                    // </Col>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Faq;
