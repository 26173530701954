import {useState} from 'react'
import {Collapse} from 'react-bootstrap'
import {BiChevronDown, BiChevronUp} from 'react-icons/bi'
import "./style.scss";

interface FaqItemProps {
  question: string
  content: string
  id: number
}

const FaqItem = ({question, content, id}: FaqItemProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="faq_container mb-4">
      <div
        className="d-flex"
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        <div className="text-start">
          <h4>{question}</h4>
        </div>
        <div>
          {open ? <BiChevronUp size="35px" /> : <BiChevronDown size="35px" />}
        </div>
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text">
          <p
            className="mt-3 text-start ms-3"
            style={{ fontSize: "18px", fontWeight: "600" }}
          >
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            }
          </p>
        </div>
      </Collapse>
    </div>
  );
}

export default FaqItem
