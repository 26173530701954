import { useEffect, useState } from "react";
import "./style.scss";
import squadLogo from "../../../assets/DingerLogoNew.png";
import { mobileWidth } from "../../../utils";
const About = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div className="about" id="about">
      <img
        src={
          "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094597/Dinger/bzkukhexcvazogqi7pso.png"
        }
        alt=""
        style={{
          position: "absolute",
          top: isMobile ? "-53px" : "-70px",
          left: isMobile ? "15%" : "40%",
          width: isMobile ? "280px" : "350px",
          zIndex: "25",
        }}
      />{" "}
      <div>
        <div className="">
          <img className="squad" src={squadLogo} alt="" />
          <p>
            <b>Welcome to Dinger Squad</b>. Our mission is to disrupt current
            Baseball culture and traditional collectibles, by empowering
            Baseball Legends who exemplify organic talent, skill, and inspiring
            others in the spirit of vintage baseball. <b>Bridging</b> America's
            National Pastime into a decentralized world of gaming, NFTs,
            Metaverse and global baseball audience.
          </p>
          <p>
            <b>Dinger Squad & Crypto Sluggers</b> are partnering and with securing the most
            distinguished legends of Professional Baseball. Childhood baseball
            NFTs are just a hint of several game changing milestones to come.
          </p>
          <p>
            Our flagship portfolio journey will include{" "}
            <b>Professional Baseball Legend collectibles,</b> outside the box
            ideology <b>Play-to-Earn GameFi</b>, and a full stack innovative
            <b> Metaverse</b>. Plus, a <b>Utility/DAO</b> token to support
            gaming and voting power for several creative use cases in the
            baseball world.
            <b>
              {" "}
              Review our <a href="#roadmap">9 PHASES</a> of our amazing
              innovating journey.
            </b>
          </p>
          <p className="m-0">
            We have also established a baseball charity which will encompass a
            gateway to supporting kids, families and parks in over 100 nations
            who play baseball.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
