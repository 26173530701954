import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SocialWidget from "../SocialWidget";
import logo from "../../assets/DingerLogoGrey.png";
import { mobileWidth } from "../../utils";

const Footer = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div style={{ background: "#414141" }}>
      <Row style={{ padding: "55px 0" }}>
        <Col lg={12} className="d-flex justify-content-center">
          <div className="d-flex">
            <img src={logo} alt="" width={155} />
          </div>
        </Col>
   
        <Col lg={12} className="d-flex justify-content-center mt-4">
          <SocialWidget color={"#000"} isRow={true} size={40} sizeFb={31} />
        </Col>
      </Row>
      <Row
        style={{
          borderTop: "1px solid #878787",
          padding: isMobile ? "24px 5px 0" : "20px 55px 0",
        }}
      >
        <Col lg={4}>
          <div className="d-flex">
            <p style={{ color: "white", fontSize: "16px" }}>Terms Of Service</p>
            <p
              style={{
                borderLeft: "1px solid black",
                marginLeft: "10px",
                paddingLeft: "10px",
                color: "white",
              }}
            >
              Privacy Policy
            </p>
            <p
              style={{
                borderLeft: "1px solid black",
                marginLeft: "10px",
                paddingLeft: "10px",
                color: "white",
              }}
            >
              Financial Disclaimer
            </p>
            <p
              style={{
                borderLeft: "1px solid black",
                marginLeft: "10px",
                paddingLeft: "10px",
                color: "white",
              }}
            >
              Accessibility Statement
            </p>
          </div>
        </Col>
        <Col lg={4}>
          <div className="d-flex justify-content-end">
            <p style={{ color: "#878787", fontSize: "16px" }}>
              Copyright &#169; 2022 Dinger Squad LLC. All Rights Reserved.
            </p>
          </div>
         
        </Col>
        <Col lg={4}>
         
          <div className="d-flex justify-content-end">
            
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
