import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
import"./style.scss"
export let web3=null;

export const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Web 3 Modal Demo",
      infuraId: "5c441734b2764b399b73da1eefd6e471",
    },
    display: {
      name: "Coinbase",
      description: " ",
    },
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: "5c441734b2764b399b73da1eefd6e471",
    },
    display: {
      name: "WalletConnect",
      description: " ",
    },
  },
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: false, // optional
  providerOptions, // required
});

export const connectWallet=async ()=>{
  web3Modal.clearCachedProvider();
  const provider = await web3Modal.connect();

  web3 = new Web3(provider || "https://speedy-nodes-nyc.moralis.io/362fc40c1ab324c15e79d4da/polygon/mumbai");

  console.log(await web3.eth.getAccounts(), provider);
  return await web3.eth.getAccounts()
  
}


