import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { mobileWidth } from '../../../utils';
import "./../WhitelistModal/whitelist.scss";

const WhitePaper = ({showModal}) => {
  const [isMobile, setisMobile] = useState(false);
  const [show, setShow]= useState(false)
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  
  return (
    <Modal show={show}>
      <div className="whitelist">
        <div className="cross" onClick={() => setShow(false)}>
          <p className="m-0">x</p>
          <p className="m-0 close">close</p>
        </div>

        <div
          style={{
            fontSize: "38px",
            position: isMobile ? "absolute" : "relative",
            padding:isMobile?"10px":"",
            top:isMobile?"40%":""
          }}
        >
          <h1>
            <b>Dinger Squad</b> Whitepaper is coming soon
          </h1>
        </div>
      </div>
    </Modal>
  );
  
}

export default WhitePaper