import Banner from '../components/HomePage/Banner'
import Faq from '../components/HomePage/Faq'
import About from '../components/HomePage/About'
import Minting from '../components/HomePage/Minting'
import Reveal from '../components/HomePage/Reveal'
import Roadmap from '../components/HomePage/Roadmap'
import TheSquad from '../components/HomePage/TheSquad'
import ChampionClub from '../components/HomePage/ChampionClub'
import Team from '../components/HomePage/Team'
import WhiteListModal from '../components/Modals/WhitelistModal/WhiteListModal'

const Home = () => {
  return (
    <div>
      {/* <WhiteListModal /> */}
      <Banner />
      <Reveal />
      <TheSquad />
      <Minting />
      <About />
      <ChampionClub />
      <Roadmap />
      <Team />
      <Faq />
    </div>
  );
}

export default Home