import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/home';
import {
  BrowserRouter as Router,
  Route,  
  Switch
  } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhiteListForm from './components/WhiteListForm';
import Gallery from './pages/gallery/gallery';

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer limit={3} />
        <Header />

        <Switch>
          <Route path="/whitelist">
            <WhiteListForm />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
