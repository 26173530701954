import React, { useEffect, useState } from "react";
import { Button, Form, FormLabel, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import badge from "../../assets/Badge.png";
import { mobileWidth, WhitelistContractABI, WhitelistContractAddress, WhitelistRPCUrl, WhitelistWalletKey } from "../../utils";
import Provider from "@truffle/hdwallet-provider";
import Web3 from "web3";

const WhiteListForm = () => {
  const [isMobile, setisMobile] = useState(false);
  const [userWallet, setWallet] = useState("");
  const [discordU, setDiscordU] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };


  const onSubmit=async ()=>{
    try{
    setIsLoading(true)
      const provider = new Provider(WhitelistWalletKey, WhitelistRPCUrl);
      const web3 = new Web3(provider);
      
      const account = web3.eth.accounts.wallet.add(WhitelistWalletKey);

      //@ts-ignore
      const whitelistContract = new web3.eth.Contract(WhitelistContractABI, WhitelistContractAddress)

      const transGas = await whitelistContract.methods
        .applyForWhitelist(
           discordU,
          userWallet,
        )
        .estimateGas(
          { gas: 3000000, from: account.address },
          function (error, gasAmount) {
            if (error) {
              console.log(error?.message);
              setIsLoading(false);
              toast.error("Failed to submit. Try Again");
            }
          }
        );

         await whitelistContract.methods
           .applyForWhitelist(
             discordU,
             userWallet,
           )
           .send({
             from: account.address,
             gas: transGas + 100000,
           }).then((trans)=>{
             console.log("trans", trans);
              setIsLoading(false);

             if(trans){
               setIsSuccessful(true)
             }
           }).catch((err)=>{
              console.log(err);
              toast.error("Failed to submit. Try Again");
              setIsLoading(false);
           });
          }
          catch(err){
            console.log(err);
            setIsLoading(false);
            toast.error("Failed to submit. Try Again");
          }
          setIsLoading(false);
           
  }

  return (
    <div style={{ marginTop: "150px", padding: isMobile ? "0 15px" : "" }}>
      <p
        style={{
          position: "relative",
          display: "flex",
          fontFamily: "Fredericka the Great",
          fontSize: isMobile ? "36px" : "36px",
          color: "#a62f24",
          zIndex: "260",
          textAlign: "center",
          width: "100%",
          marginLeft: isMobile ? "25%" : "45%",
        }}
      >
        WhiteList I
        <img
          src={badge}
          alt=""
          style={{
            position: "absolute",
            top: isMobile ? "-80%" : "-80%",
            left: isMobile ? "-85px" : "-85px",
            width: "350px",
            zIndex: "-25",
          }}
        />
      </p>

      <h3 style={{ marginTop: "55px" }}>
        Thank you for completing the <br></br>
        <b>Dinger Squad</b> required discord tasks{" "}
      </h3>
      <p style={{ marginTop: "35px", fontSize: "18px" }}>
        You may enter your discord username and wallet address below
      </p>
      <Form>
        <Form.Group>
          <Form.Label style={{ fontWeight: "bold", fontSize: "18px" }}>
            Discord username
          </Form.Label>
          <Form.Control
            style={{ width: "250px", margin: "auto" }}
            value={discordU}
            onChange={(e) => setDiscordU(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label
            style={{ fontWeight: "bold", fontSize: "18px", marginTop: "35px" }}
          >
            ERC20 Wallet Address
          </Form.Label>
          <Form.Control
            style={{ width: "250px", margin: "auto" }}
            value={userWallet}
            onChange={(e) => setWallet(e.target.value)}
          />
        </Form.Group>
        {!isSuccessful ? (
          <Button
            style={{
              width:"150px", 
              margin: "35px 0",
              backgroundColor: "#a62f24",
              border: "none",
              fontSize: "20px",
            }}
            className="connect"
            onClick={() => onSubmit()}
          >
            {isLoading ? <Spinner animation="border" /> : "Submit"}
          </Button>
        ) : (
          <p
            style={{
              fontSize: isMobile ? "24px" : "36px",
              fontFamily: "Fredericka the Great",
              color: "green",
            }}
          >
            Successful Submission<br></br> Congratulations!!
          </p>
        )}
      </Form>
    </div>
  );
};

export default WhiteListForm;


// const onSubmit = async () => {
  //   var data = JSON.stringify({
  //     wallet: wallet,
  //     discord: discordU,
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://tgk-backend.herokuapp.com/upload/user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log("res", response);
  //       setIsSuccessful(true)
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       toast.error("Failed to submit. Try Again")
  //     });
  // };
