import { useEffect, useState } from "react";
import "./style.scss";
import TeamCardList from "../TeamCardList";
import { mobileWidth } from "../../../utils";

const Team = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div className="team" id="team">
      <img
        src={
          "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094602/Dinger/ypmitdgixm84rx5ddil0.png"
        }
        alt=""
        style={{
          position: "absolute",
          top: isMobile ? "-100px" : "-114px",
          left: isMobile ? "15%" : "40%",
          width: isMobile ? "280px" : "350px",
          zIndex: "25",
        }}
      />
      <div>
        <TeamCardList
          width={isMobile ? 350 : 530}
          height={isMobile ? 350 : 490}
        />
      </div>
    </div>
  );
};

export default Team;
