import { useEffect, useState } from "react";
import "./style.scss";
import { Col, Row } from "react-bootstrap";
import { mobileWidth } from "../../../utils";

const ChampionClub = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div className="champion" id="champion">
      <img
        src={
          "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094597/Dinger/fchtnzkgtfamzbcqxvgp.png"
        }
        alt=""
        style={{
          position: "absolute",
          top: isMobile ? "-53px" : "-70px",
          left: isMobile ? "15%" : "40%",
          width: isMobile ? "280px" : "350px",
          zIndex: "25",
        }}
      />
      <Row className="">
        <Col lg={6}>
          <img
            className="squad"
            src={
              "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094600/Dinger/aokv631fsarblfvo04ww.png"
            }
            alt=""
          />
        </Col>
        <Col lg={6} className="left mt-4 mt-md-0">
          <p>
            Champions club is a way to{" "}
            <b>reward our Dinger Squad NFT holders</b> with{" "}
            <b>premium membership perks</b>. Member’s-only are extended invites
            to socials & parties that include our{" "}
            <b>professional baseball legends and ambassadors.</b> Imagine
            meeting your past-time legend in real life? With our Crypto Sluggers
            partnership, your dream will come true.
          </p>
          <p className="m-0">
            <b>Additional Club benefits:</b>
          </p>
          <p className="m-0">
            {" "}
            &bull;{" "}
            <span>
              100 NFT holders will be randomly drawn to receive an{" "}
              <b>AUTOGRAPHED</b> baseball from a <b>LEGEND</b>.
            </span>
            
          </p>
          <p className="m-0">
            {" "}
            &bull; Discounts on Dinger Squad merch & collectibles
          </p>
          <p className="m-0">
            &bull; <b>Whitelisting</b> for our presale <b>legends NFT</b> drops
          </p>
          <p className="m-0">
            {" "}
            &bull; <b>Whitelisting</b> for Crypto Slugger <b>token pre-sale</b>
          </p>{" "}
          <p className="m-0">
            {" "}
            &bull; <b>Meet your Legend</b> at the ball game
          </p>{" "}
          <p className="m-0">
            &bull; NFT <b>staking</b>
          </p>
          <p className="m-0">
            &bull; <b>Receive Crypto Sluggers game tokens to redeem for play</b>
          </p>
          <p className="m-0">&bull; More announcements to come...</p>
        </Col>
      </Row>
    </div>
  );
};

export default ChampionClub;
