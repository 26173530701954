import { useEffect, useState } from "react";
import "./style.scss";
import bgDesk from "../../../assets/Roadmap_Field.png";
import dot from "../../../assets/roadmap_dot.png";
import { mobileWidth } from "../../../utils";

const Roadmap = () => {
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  return (
    <div className="roadmap" id="roadmap">
      <img
        src={
          "https://res.cloudinary.com/dhmglymaz/image/upload/v1654094599/Dinger/lkxgcaxz0zbhepyx3rsr.png"
        }
        alt=""
        style={{
          position: "absolute",
          top: isMobile ? "-53px" : "-70px",
          left: isMobile ? "15%" : "40%",
          width: isMobile ? "280px" : "350px",
        }}
      />{" "}
      {isMobile ? (
        <div
          style={{
            height: "100%",
            position: "relative",
            marginInline: "15px",
            padding: "75px 0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              float: "left",
              width: "55%",
              marginLeft: "45%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} className="red" />
            <div
              style={{ textAlign: "start", color: "white", marginLeft: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase I, 2022
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Planning, design & buildout <br></br>Raise pre-seed funding
                <br></br>Secure baseball legends
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "55%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} className="red" />
            <div
              style={{ textAlign: "end", color: "white", marginRight: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase II, 2022
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Collections Smart contracts & mint ready <br></br> Partner with
                Crypto Sluggers <br></br>Secure Upper Echelon Advisors &
                Partners <br></br>Launch Discord & social media channels
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              float: "left",
              width: "55%",
              marginLeft: "45%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "start", color: "white", marginLeft: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase III, 2022
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Marketing hype & Community Building <br></br> Whitelist Dinger
                Squad Legends Collections <br></br>Pre-sale & public minting
                begins
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "55%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "end", color: "white", marginRight: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase IV, 2023
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Community enrichment, giveaways, air-drops<br></br> Launch
                Baseball Charity <br></br>Announce Baseball Legend Ambassadors
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              float: "left",
              width: "55%",
              marginLeft: "45%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "start", color: "white", marginLeft: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase V, 2023
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Limited Edition Baseball Legends Collection launch<br></br>{" "}
                Token presale, Private Round Fundraising <br></br>
                Investor/partnership announcements
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "55%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "end", color: "white", marginRight: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase VI, 2023
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Establish Crypto Sluggers GameFi Design Council<br></br>{" "}
                Announce GameFi & Metaverse Development partner <br></br>Launch
                Utility/DAO Token (IDOs & IEOs)
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              float: "left",
              width: "55%",
              marginLeft: "45%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "start", color: "white", marginLeft: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase VII, 2023
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Launch staking platform <br></br>Announce GameFi NFT roster{" "}
                <br></br>Initial GameFi development
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "55%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "end", color: "white", marginRight: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase VIII, 2023
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Crypto Sluggers GameFi release <br></br>Game Perks, P2E, New
                Collections, and More... <br></br>Global Tokenized tournaments
                launch
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              float: "left",
              width: "55%",
              marginLeft: "45%",
            }}
          >
            <img src={dot} alt="" width={30} height={30} style={{}} />
            <div
              style={{ textAlign: "start", color: "white", marginLeft: "10px" }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase IX, 2024
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Baseball Metaverse Production
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            position: "relative",
            top: "20%",
          }}
        >
          <img src={bgDesk} alt="" className="bgDesk" />

          <div
            style={{
              position: "absolute",
              top: "35%",
              left: "44%",
              textAlign: "start",
            }}
          >
            <img
              src={dot}
              alt=""
              width={30}
              className="red"
              style={{
                marginLeft: "65px",
              }}
            />
            <div style={{ textAlign: "start", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase I, 2022
              </p>
              <p>
                Planning, design & buildout <br></br>Raise pre-seed funding
                <br></br>Secure baseball legends
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "27%",
              left: "45%",
              textAlign: "start",
            }}
          >
            <img
              src={dot}
              alt=""
              className="red"
              width={30}
              style={{ marginLeft: "65px" }}
            />
            <div style={{ textAlign: "start", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase II, 2022
              </p>
              <p>
                Collections Smart contracts & mint ready <br></br> Partner with
                Crypto Sluggers <br></br>Secure Upper Echelon Advisors &
                Partners <br></br>Launch Discord & social media channels
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "35%",
              left: "72%",
              textAlign: "start",
            }}
          >
            <img src={dot} alt="" width={30} style={{}} />
            <div style={{ textAlign: "start", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase III, 2022
              </p>
              <p>
                Marketing hype & Community Building <br></br> Whitelist Dinger
                Squad Legends Collections <br></br>Pre-sale & public minting
                begins
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "35%",
              left: "8%",
              textAlign: "end",
            }}
          >
            <img src={dot} alt="" width={30} style={{ marginLeft: "65px" }} />
            <div style={{ textAlign: "end", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase IV, 2023
              </p>
              <p>
                Community enrichment, giveaways, air-drops<br></br> Launch
                Baseball Charity <br></br>Announce Baseball Legend Ambassadors
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "60%",
              textAlign: "start",
            }}
          >
            <img src={dot} alt="" width={30} style={{}} />
            <div style={{ textAlign: "start", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase V, 2023
              </p>
              <p>
                Limited Edition Baseball Legends Collection launch<br></br>{" "}
                Token presale, Private Round Fundraising <br></br>
                Investor/partnership announcements
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "20%",
              textAlign: "end",
            }}
          >
            <img src={dot} alt="" width={30} style={{ marginLeft: "65px" }} />
            <div style={{ textAlign: "end", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase VI, 2023
              </p>
              <p>
                Establish Crypto Sluggers GameFi Design Council<br></br>{" "}
                Announce GameFi & Metaverse Development partner <br></br>Launch
                Utility/DAO Token (IDOs & IEOs)
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "5%",
              textAlign: "end",
            }}
          >
            <img src={dot} alt="" width={30} style={{}} />
            <div style={{ textAlign: "end", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase VII, 2023
              </p>
              <p>
                Launch staking platform <br></br>Announce GameFi NFT roster{" "}
                <br></br>Initial GameFi development
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-12%",
              left: "45%",
              textAlign: "start",
            }}
          >
            <img src={dot} alt="" width={30} style={{ marginLeft: "65px" }} />
            <div style={{ textAlign: "start", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase VIII, 2023
              </p>
              <p>
                Crypto Sluggers GameFi release <br></br>Game Perks, P2E, New
                Collections, and More... <br></br>Global Tokenized tournaments
                launch
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "75%",
              textAlign: "start",
            }}
          >
            <img src={dot} alt="" width={30} style={{}} />
            <div style={{ textAlign: "start", color: "white" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#ddcba3",
                }}
              >
                Phase IX, 2024
              </p>
              <p>Baseball Metaverse Production</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Roadmap;
