import smokey1 from "./assets/smokey/1.png";
import smokey2 from "./assets/smokey/2.png";
import smokey3 from "./assets/smokey/3.png";
import smokey4 from "./assets/smokey/4.png";
import smokey5 from "./assets/smokey/5.png";

import bolt1 from "./assets/bolt/1.png"
import bolt2 from "./assets/bolt/2.png";
import bolt3 from "./assets/bolt/3.png";
import bolt4 from "./assets/bolt/4.png";
import bolt5 from "./assets/bolt/5.png";

import chulo1 from "./assets/chulo/1.png";
import chulo2 from "./assets/chulo/2.png";
import chulo3 from "./assets/chulo/3.png";
import chulo4 from "./assets/chulo/4.png";
import chulo5 from "./assets/chulo/5.png";

import revolt1 from "./assets/revolt/1.png";
import revolt2 from "./assets/revolt/2.png";
import revolt3 from "./assets/revolt/3.png";
import revolt4 from "./assets/revolt/4.png";
import revolt5 from "./assets/revolt/5.png";

import specs1 from "./assets/specs/1.png";
import specs2 from "./assets/specs/2.png";
import specs3 from "./assets/specs/3.png";
import specs4 from "./assets/specs/4.png";
import specs5 from "./assets/specs/5.png";

import coach1 from "./assets/coach/1.png";
import coach2 from "./assets/coach/2.png";
import coach3 from "./assets/coach/3.png";
import coach4 from "./assets/coach/4.png";
import coach5 from "./assets/coach/5.png";

import fireball1 from "./assets/fireball/1.png";
import fireball2 from "./assets/fireball/2.png";
import fireball3 from "./assets/fireball/3.png";
import fireball4 from "./assets/fireball/4.png";
import fireball5 from "./assets/fireball/5.png";

import shorty1 from "./assets/shorty/1.png";
import shorty2 from "./assets/shorty/2.png";
import shorty3 from "./assets/shorty/3.png";
import shorty4 from "./assets/shorty/4.png";
import shorty5 from "./assets/shorty/5.png";

import tiny1 from "./assets/tiny/1.png";
import tiny2 from "./assets/tiny/2.png";
import tiny3 from "./assets/tiny/3.png";
import tiny4 from "./assets/tiny/4.png";
import tiny5 from "./assets/tiny/5.png";


import placeholder from "./assets/banner-nft.png";

export const mobileWidth= 768; 

export const smallLapWidth = 1500; 


export const WhitelistRPCUrl =
  "https://speedy-nodes-nyc.moralis.io/362fc40c1ab324c15e79d4da/eth/rinkeby";

export const WhitelistWalletKey = "233fe783502de848a65be43c745aaa2e4fa90d6b0b5387b9e77186ad4e6dd7d5";

export const WhitelistContractAddress = "0x99265953CEBB3B94540EE1085Bf7B2210109AA45";

export const WhitelistContractABI = [
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "AllEntries",
    outputs: [
      { internalType: "string", name: "discord", type: "string" },
      { internalType: "address", name: "user", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "discord", type: "string" },
      { internalType: "address", name: "user", type: "address" },
    ],
    name: "applyForWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "entry",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "idToWhitelist",
    outputs: [
      { internalType: "string", name: "discord", type: "string" },
      { internalType: "address", name: "user", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
];


export const collectionDates = [
  new Date("2022-06-28T05:00:00Z").getTime(),
  new Date("2022-06-29T05:00:00Z").getTime(),
  new Date("2022-06-30T05:00:00Z").getTime(),
  new Date("2022-07-01T05:00:00Z").getTime(),
  new Date("2022-07-02T05:00:00Z").getTime(),
  new Date("2022-07-03T05:00:00Z").getTime(),
  new Date("2022-07-04T05:00:00Z").getTime(),
  new Date("2022-07-05T05:00:00Z").getTime(),
  new Date("2022-07-06T05:00:00Z").getTime(),
  new Date("2022-07-07T05:00:00Z").getTime(),
];

export const CollectionName = [
  {
    name: "smokey",
    contractAddress: "0x21702036f0a6076C56421B04Ca41F4fF5EC99344",
    attributes: {
      BACKGROUND: [
        "FIELD-BLUE-GRAY-SUNSET",
        "HARLEY-BLUE-ORANGE-SUNSET",
        "PENNANT-FLAG-ORANGE-SUNSET",
      ],
      EYES: ["DEFAULT-EYES", "TRIBAL-FACE", "EYE-PATCH"],
      "NECK TATTOO": ["NONE", "CHINESE-LETTERS-TATTOO", "TRIBAL-NECK"],
      CLOTHING: [
        "BLUE-LETTERMAN-JACKET",
        "BLUE-WHITE-BASEBALL-SHIRT",
        "BLACK-BIKER-VEST",
        "RED-STRIPE-UNIFORM",
        "RED-HOODIE",
      ],
      "ARM TATTOO": ["NONE", "TRISHUL-TATTOO"],
      NECK: ["NOTHING", "ZEN-HEADPHONES", "LEATHER-CHOKER"],
      HEADWEAR: [
        "GRAY-GREEN-WILD",
        "SKULL-DOO-RAG",
        "BLUE-&-RED-CAP",
        "BLUE-BASEBALL-HELMET",
        "SAMURAI",
        "SHORT-SPIKY-SILVER",
        "RED-HIGHLIGHTS",
      ],
      MOUTH: [
        "VAPE-SMOKE",
        "DEFAULT-MOUTH",
        "USA-SKULL-MASK",
        "BIG-BLUNT",
        "BUBBLE-GUM",
        "CIGARETTE",
        "CIGAR",
      ],
      EAR: ["SKULL", "CONE", "NOTHING", "GOTHIC-CROSS"],
      HAND: [
        "BROWN-WOOD-BAT",
        "GREY-BEIGE-WOOD-BAT",
        "UKRAINE-BOXING-GLOVE",
        "GUITAR",
        "BLUE-WOOD-BAT",
        "KATANA-SWORD",
      ],
    },
    slides: [
      //  `https://drive.google.com/thumbnail?id=1RGucz2GwW_dpTSS6x3NzA-Q2f7Q8gIB1&sz=w$650-h650`,
      //  `https://drive.google.com/thumbnail?id=1d_UrTpbXlfRuZelHLw-hoTYkjjG0w9nE&sz=w$650-h650`,
      //  `https://drive.google.com/thumbnail?id=1cLMfWI4O472FpCzrE0XV6lvOOrV-7eY&sz=w$650-h650`,
      //  `https://drive.google.com/thumbnail?id=1V12LX80_2r7ts2-N4VWGaQuYO2E6cOfS&sz=w$650-h650`,
      //  `https://drive.google.com/thumbnail?id=1nOE1UYy0dSDGDC_2dKMO7LLoaSELeopD&sz=w$650-h650`,
      smokey1,
      smokey2,
      smokey3,
      smokey4,
      smokey5,
    ],
  },
  {
    name: "specs",
    contractAddress: "0x119Ad35819906DaEB4457e4dB632a3EC369D06BB",
    attributes: {
      BACKGROUND: [
        "BACKGROUND-BLUE",
        "BACKGROUND-PURPLE-CLOUDS",
        "BACKGROUND-GOLDEN-AND-BLUE",
      ],
      BACKPACK: [
        "BACKPACK-YELLOW",
        "BACKPACK-RED",
        "BACKPACK-GREEN",
        "BACKPACK-BLUE",
      ],
      "ACCESSORIES INSIDE BACKPACK": [
        "SKATEBOARD-BLUE-SLUGGER",
        "SKATEBOARD-YELLOW-WITH-RED-CIRCLES",
        "WOODEN-BAT-BLUE",
        "WOODEN-BAT-YELLOW",
        "SKATEBOARD-DOODLE-ART",
        "WOODEN-BAT-RED",
        "SKATEBOARD-ORANGE-WITH-BLUE-CIRCLES",
        "SKATEBOARD-GOLDEN",
      ],
      "ACCESSORIES IN FREE HAND": [
        "EMPTY-HAND",
        "PARTY-HORN",
        "WATCH",
        "BASEBALL",
        "COLA-BOTTLE",
        "DOG-LEASH",
        "BOBBLEHEAD",
      ],
      CLOTHING: [
        "BLUE-STRIPED-SHIRT",
        "YELLOW-SHIRT",
        "GUYABARA-SHIRT",
        "SKELETON-SHIRT",
        "YELLOW-STRIPED-SHIRT",
        "ARMY-JACKET",
        "BLUE-WHITE-BASEBALL-SHIRT",
      ],
      GLOVE: [
        "GLOVE-BROWN",
        "GLOVE-GREEN",
        "GLOVE-BLACK",
        "GLOVE-RED",
        "GLOVE-BLUE",
        "GLOVE-WHITE",
        "GLOVE-YELLOW",
      ],
      CHEEK: ["WITHOUT-KISS", "LIPSTICK-KISS"],
      EYES: [
        "REGULAR-GLASSES",
        "3D-GLASSES",
        "HYPNOTIC-GLASSES",
        "FUNNY-OVERSIZED-GLASSES",
        "FALLING-EYES",
        "CYBORG-GLASSES",
        "GREEN-GLASSES",
        "PIRATE-EYE-PATCH",
      ],
      MOUTH: [
        "GOLD-GRILL",
        "KAZOO",
        "MISSING-TEETH",
        "PARTY-HORN",
        "BUBBLEGUM",
        "MULTI-COLOR-TEETH",
        "SMILING",
      ],
      HEADWEAR: [
        "HELICOPTER-HAT",
        "BLACK-CAP",
        "RED&BLUE-BASEBALL-CAP",
        "CYCLING-CAP",
        "TALL-HAT",
        "WITHOUT-HAT",
        "FEDORA",
        "ARMY-CAP",
      ],
    },
    slides: [specs1, specs2, specs3, specs4, specs5],
  },
  {
    name: "chulo",
    contractAddress: "0x439bCDf89d8D40Df3c5C1B5392BE789b02eB5FA1",
    attributes: {
      BACKGROUND: [
        "GREEN-SHADOW-BOX",
        "BROWN-SHADOW-BOX",
        "RED-SHADOW-BOX",
        "BLUE-SHADOW-BOX",
      ],
      "BOX ITEMS RIGHT": [
        "NOTHING",
        "ORANGE-MELTED-CANDLE",
        "EUROPEAN-SPEAR-HEAD",
        "PAINTED-LOVE-STONE",
        "WHITE-WOOD-ROSARY",
      ],
      "BOX ITEMS LEFT": ["PEACE-STONE", "NOTHING", "BIRD", "SKULL-CANDLE"],
      CLOTHING: [
        "1930S-UNIFORM",
        "ELVIS-OUTFIT",
        "AMERICAN-FLAG-OVERALLS",
        "PINSTRIPE-UNIFORM",
        "BROWN-WOOD-ROSARY",
        "TRADITIONAL-SOUTH-AMERICA",
      ],
      HEADWEAR: [
        "STRAW-FEDORA",
        "WHITE-FRO",
        "90S-BUCKET-HAT",
        "RAINBOW-TALL-HAT",
        "RAINBOW-FRO",
        "ELVIS-HAIRDOO",
        "BLUE-FRO",
      ],
      MOUTH: ["CIGAR", "BLUNT", "VAPE-PEN", "NOTHING"],
      EYES: [
        "NONE",
        "SKULL-GLASSES",
        "BASEBALL-BLUE-SUNGLASSES",
        "CIRCULAR-EYEGLASSES",
        "ELVIS-SUNGLASSES",
      ],
      "RIGHT HAND": [
        "BLACK-BASEBALL",
        "BASEBALL",
        "BEER-BOTTLE",
        "AFRICAN-SPEAR",
      ],
      "LEFT HAND": [
        "BABY-BLUE-BIEGE-GLOVE",
        "RED-GLOVE",
        "RUM-SPLASH",
        "AFRICAN-SHIELD",
        "VUDOO-DOLL",
        "BLACK-BROWN-GLOVE",
      ],
    },
    slides: [chulo1, chulo2, chulo3, chulo4, chulo5],
  },
  {
    name: "shorty",
    contractAddress: "0xEa39CB2063d21A2612077B2Afae30734d925115C",
    attributes: {
      BACKGROUND: [
        "WOOD-FENCE-ORANGE-SUNSET",
        "BARBED-WIRE-PURPLE-PINK-SUNSET",
        "WIRE-FENCE-GRAY-ORANGE-SUNSET",
      ],
      TATTOOS: ["NONE", "MUSIC-NOTES", "OM-SHANTI"],
      ARM: [
        "BLACK-MECHANICAL-ARM",
        "TEAL-MECHANICAL-ARM",
        "RED-MECHANICAL-ARM",
      ],
      HAND: [
        "UKRAINE-FLAG",
        "BEIGE-GLOVE",
        "BROWN-GLOVE",
        "BLUE-WOOD-BAT",
        "RED-WHITE-BLUE-GLOVE",
        "KATANA-SWORD",
        "MELTED-ICE-CREAM",
        "BASEBALL",
      ],
      CLOTHING: [
        "PINK-PRINT-SHIRT",
        "BLUE-STRIPE-SHIRT",
        "PURPLE-PINK-PRINT-SHIRT",
        "RED-STRIPE-UNIFORM",
        "NINJA-UNIFORM",
      ],
      HEADWEAR: [
        "WILD-HAIR",
        "LONG-HAIR",
        "ARMY-GREEN-CAP",
        "TIE-DYE-BLUE-CAP",
        "SAMURAI-HAIR",
        "FIREFIGHTER-HAT",
        "SHUWEKK-RETRO-VINTAGE",
      ],
      MOUTH: [
        "MISSING-TEETH",
        "STRAW",
        "BUBBLE-GUM",
        "GRIN",
        "VAMPIRE-MOUTH-FACE-MASK",
        "SMIRK",
      ],
      EYES: [
        "DROOPING-EYES",
        "BLUE-EYE",
        "BROWLINE-EYEGLASSES",
        "SQUARE-BLACK-EYEGLASSES",
        "ROUND-RED-EYEGLASSES",
      ],
    },
    slides: [shorty1, shorty2, shorty3, shorty4, shorty5],
  },
  {
    name: "tiny",
    contractAddress: "0x9204c9bef844CF0647959B791f41f4d3c044C6D3",
    attributes: {
      BACKGROUND: ["BLUE-PINK-SKY", "ORANGE-PINK-SKY", "YELLOW-ORANGE-SKY"],
      "LEFT HAND": [
        "LARGE-LOLLIPOP",
        "POINTING-FINGER",
        "BASEBALL",
        "UKRAINE-FLAG",
        "MIDDLE-FINGER",
      ],
      TATTOO: [
        "ICE-CREAM",
        "IRISH-HORSESHOE",
        "WITHOUT-TATTOO",
        "IRISH-TATTOO",
        "ROLLING-STONES",
      ],
      CLOTHING: [
        "BASEBALL-T-SHIRT",
        "BLUE-STRIPED-T-SHIRT",
        "SCHOOL-SAFETY-VEST",
        "ORANGE-STRIPED-T-SHIRT",
        "PRIDE-RAINBOW-T-SHIRT",
        "GOLD-CHAIN",
      ],
      "RIGHT HAND": [
        "BROWN-WOOD-BAT",
        "RED-WOOD-BAT",
        "GREEN-WOOD-BAT",
        "MEDIEVAL-SWORD",
        "YELLOW-WOOD-BAT",
        "VIKING-AXE",
      ],
      MOUTH: ["SMIRK", "OPEN", "PURPLE-TONGUE", "ZZ-TOP-BEARD"],
      EYES: [
        "LEATHER-EYEPATCH",
        "CIRCULAR-EYEGLASSES",
        "NOTHING",
        "NO-GLARE-STRIPS",
      ],
      HEADWEAR: [
        "PROPELLER-CAP",
        "WILD-RED",
        "BLACK-CAP",
        "COYOTE-FUR-HAT",
        "WILD-BLONDE",
      ],
    },
    slides: [tiny1, tiny2, tiny3, tiny4, tiny5],
  },
  {
    name: "fireball",
    contractAddress: "0x2Ab57731c750bB3cE55C9Bfbd64A811654fC2a16",
    attributes: {
      background: [
        "BLUE-PURPLE-SUNSET-STADIUM",
        "BLUE-PURPLE-SUNSET",
        "NAVY-SKY-PARK",
        "RED-ORANGE-SUNSET-STADIUM",
        "PURPLE-SKY-PARK",
      ],
      clothing: [
        "BLACK-BIKER-VEST",
        "BLUE-WHITE-BASEBALL-SHIRT",
        "RED-STRIPE-JERSEY",
        "SLEEVELESS-RED-STRIPE-JERSEY",
        "WHITE-STRIPE-99-JERSEY",
      ],
      headwear: [
        "BLACK-WILD-HAIR",
        "PINK-HAIR",
        "BLACK-SKULL-BEENIE",
        "BLACK-GQ-HAIR",
        "KHAKI-WILD-HAIR",
        "RED&BLUE-BASEBALL-CAP",
        "BLACK-BEENIE-HELMET",
        "WHITE-HEADBAND",
        "BLACK-PAISLEY-BANDANA",
        "BABY-BLUE-HAIR",
        "BROWN-WILD-HAIR",
      ],
      mouth: ["NONE", "CROSS", "WING", "SPIKE"],
      eyes: [
        "ANGRY",
        "SIGAR",
        "SMIRK",
        "ANGRY-GOLDEN-TEETH",
        "AMERICAN-FLAG",
        "SKULL",
        "KNIFE",
      ],
      hand: [
        "NONE",
        "SKULL",
        "REFLECTIVE-WHITE",
        "REFLECTIVE-BLACK",
        "REFLECTIVE-BLUE",
        "REFLECTIVE-RED",
        "REFLECTIVE-GOLD",
      ],
      tattoo: [
        "BROWN-BASEBALL-GLOVE",
        "ROYAL&BEIGE-BASEBALL-GLOVE",
        "MID-FINGER",
        "BLUE&GREY-BASEBALL-GLOVE",
        "UKRAINE-FLAG",
      ],
    },
    slides: [fireball1, fireball2, fireball3, fireball4, fireball5],
  },
  {
    name: "coach",
    contractAddress: "0x3abA816e8eb798336625878c6519b60caF4bC048",
    attributes: {
      BACKGROUND: [
        "GREEN-DUGOUT",
        "BLUE-DUGOUT",
        "YELLOW-DUGOUT",
        "GREY-DUGOUT",
        "RED-DUGOUT",
      ],
      BATS: ["WOOD-BROWN-BAT", "WOOD-RED-BAT", "NONE", "WOOD-YELLOW-BAT"],
      BACKLOG: ["6-PACK-BEER", "BEER-BUCKET", "NOTHING"],
      TATTOO: ["NO-TATTOO", "TRIBAL-HEART", "WOMAN-SILHOUETTE", "NECK-SKULL"],
      CLOTHING: [
        "STRIPED-SHIRT",
        "YELLOW-JACKET",
        "70S-SHIRT",
        "VINTAGE-SHIRT",
        "WESTERN-COWBOY-SHIRT",
        "LGBTQ-JERSEY",
      ],
      MOUTH: ["GREY-BEARD", "CIGAR", "SMOKE-PIPE", "NOTHING", "SMIRK"],
      EYES: [
        "BLACK-THICK-GLASSES",
        "CIRCULAR-EYEGLASSES",
        "NOTHING",
        "LEATHER-EYEPATCH",
      ],
      HEADWEAR: [
        "VINTAGE-HAT",
        "WOOL-FELT-FEDORA",
        "GREY-CURLS",
        "GOLF-HAT",
        "SILVER-BUN",
        "WORN-BLACK-CAP",
        "BEER-CAP",
      ],
      LEFT: [
        "NOTHING",
        "BASEBALL-GLOVE-RED-YELLOW",
        "BASEBALL-GLOVE-BLACK-WHITE",
        "BASEBALL-GLOVE-BROWN-BLACK",
        "BRASS-KNUCKLES",
        "BLACK-WHITE-WRIST-WATCH",
      ],
      RIGHT: ["BASEBALL", "BEER-CAN", "BEER-BOTTLE"],
    },
    slides: [coach1, coach2, coach3, coach4, coach5],
  },
  {
    name: "bolt",
    contractAddress: "0x067c7B48a7e357529Ad4d031E0CcE7cA06c5b139",
    attributes: {
      background: ["DIRT-FIELD"],
      clothing: ["HERO-CAPE", "RED-STRIPE-UNIFORM"],
      face: [
        "TRIBAL-WHITE",
        "PERSIA-KING",
        "CLEAN",
        "BLOODY-HANDPRINT",
        "ULTIMATE-WARIOR-PAINT",
        "TRIBAL-ORANGE-NEON",
        "IMMORTAL-FACE",
      ],
      mouth: [
        "PACIFIER",
        "VENDETTA-FACE-MASK",
        "RAINBOWTEETH-GRILLZ",
        "REGULAR",
        "SKULL-FACE-MASK",
        "GOLD-TEETH-GRILLZ",
        "NOT-VISIBLE",
      ],
      hat: [
        "BLACK-HEADSCARF",
        "BALD",
        "NATIVE-AMERICAN-HEADDRESS",
        "DREADLOCKS",
        "RED-BATTING-HELMET",
        "CHROME-BATTING-HELMET",
        "BIG-AFRO",
      ],
      eyes: ["BASEBALL-SUNGLASSES", "MALCOM-X-EYEGLASSES", "WITHOUT-GLASSES"],
      hands: [
        "IRON-MAN",
        "MIDDLE-FINGER",
        "NOTHING",
        "BOXING-GLOVES",
        "MEDIVEAL-SPIKED-CLUB",
        "BRASS-KNUCKLES",
        "WORLD-SERIES-RING",
        "MECHANICAL-HAND",
        "UKRAINE-FLAG",
      ],
      tattoo: ["NONE", "BLACK-LIVES-MATTER", "BOLT", "EAGLE-WINGS"],
      base: ["3RD-BASE", "ETH-BASE", "WORLD-SERIES-TROPHY", "BITCOIN-BASE"],
    },
    slides: [bolt1, bolt2, bolt3, bolt4, bolt5],
  },
  {
    name: "revolt",
    contractAddress: "0x24043F6738bD40772179fb334f5289261CC7e829",
    attributes: {
      BACKGROUND: [
        "FIELD-CUVRED-HORIZON",
        "FIELD-GRAY-SUNSET",
        "BASEBALL-SCOREBOARD-WALL",
      ],
      FOOT: [
        "SNEAKER",
        "HIGH-TOP-SHOES",
        "RETRO-CHUNKY-SNEAKERS",
        "WHITE-CLEATS-GOLD-BOTTOM",
        "HEELS",
      ],
      CLOTHING: [
        "BLING-DENIM-JACKET",
        "RETRO-UNIFORM",
        "OPEN-JERSEY",
        "CLASSIC-BASEBALL-TEE",
      ],
      ARM: [
        "WHITE-WRIST-SWEATBAND",
        "NOTHING",
        "FLORAL-TATTOO",
        "SHUT-OUT-TATTOO",
      ],
      NECK: [
        "GRL-POWER-TATTOO",
        "STAGGER-NECKLACE",
        "NOTHING",
        "GOLD-JERSEY-NUMBER-NECKLACE",
      ],
      MAKEUP: ["SMOKEY-EYE-MAKEUP", "NOTHING", "BLACK-SMEARED-FIERCE-EYE"],
      HEADWEAR: [
        "VISOR",
        "RED&BLUE-CAP",
        "BRUNETTE-CURLS",
        "LOSE-BLONDE-BANGS",
        "BACKWARDS-CAP",
        "LONG-BRUNETTE-PONYTAIL",
        "BASEBALL-CAP",
        "RED-SCRUNCHIE",
        "BRAIDS",
        "BANDANA",
      ],
      EYES: ["NOTHING", "RETRO-STYLE-EYEGLASSES", "VINTAGE-CAT-EYE-SUNGLASSES"],
      MOUTH: [
        "ROSE-LIPSTICK",
        "FULL-LIPS",
        "FULL-RED-LIPSTICK",
        "LOLLIPOP",
        "BUBBLEGUM",
        "MOUTH-GRILL",
      ],
      EAR: [
        "BASEBALL-EAR-PIERCINGS",
        "NOTHING",
        "WHITE-HEADPHONES",
        "BASEBALL-LOOP-EARRING",
        "GOLD-HOOP-EARRINGS",
      ],
    },
    slides: [revolt1, revolt2, revolt3, revolt4, revolt5],
  },
];

export const ABI = [
  {
    inputs: [
      { internalType: "string", name: "baseURI", type: "string" },
      { internalType: "uint256", name: "whitelistOneTime_", type: "uint256" },
      { internalType: "uint256", name: "whitelistEndTime_", type: "uint256" },
      { internalType: "uint256", name: "whitelistTwoTime_", type: "uint256" },
      { internalType: "uint256", name: "publicSale", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "AmountForTeamUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "string", name: "uri", type: "string" },
    ],
    name: "BaseUriUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "max", type: "uint256" },
    ],
    name: "MaxPerAddressDuringMintUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "quantity",
        type: "uint256",
      },
    ],
    name: "Minted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "MoneyWithdrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "quantity",
        type: "uint256",
      },
    ],
    name: "NftsAirdropped",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "SalePriceUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "quantity",
        type: "uint256",
      },
    ],
    name: "TeamMinted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "time",
        type: "uint256",
      },
    ],
    name: "TimeUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "size",
        type: "uint256",
      },
    ],
    name: "maxBatchSizeUpdated",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address[]", name: "users", type: "address[]" },
      { internalType: "uint256[]", name: "quantity", type: "uint256[]" },
    ],
    name: "airdropNft",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "amountForTeam",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "contractURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getOwnershipData",
    outputs: [
      {
        components: [
          { internalType: "address", name: "addr", type: "address" },
          { internalType: "uint64", name: "startTimestamp", type: "uint64" },
        ],
        internalType: "struct ERC721A.TokenOwnership",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxPerAddressDuringMint",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "quantity", type: "uint256" }],
    name: "mintNft",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nextOwnerToExplicitlySet",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "numberMinted",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "publicSalePrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "publicSaleTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "setAmountAvailableForWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "baseURI", type: "string" }],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "time", type: "uint256" }],
    name: "setEndTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "price", type: "uint256" }],
    name: "setPublicSalePrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "time", type: "uint256" }],
    name: "setPublicSaleTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "price", type: "uint256" }],
    name: "setwhitelistPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "time", type: "uint256" }],
    name: "setwhitelistTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "price", type: "uint256" }],
    name: "setwhitelistTwoPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "time", type: "uint256" }],
    name: "setwhitelistTwoTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "quantity", type: "uint256" }],
    name: "teamMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
    name: "tokenByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "treasury",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "max", type: "uint256" }],
    name: "upadteMaxPerAddressDuringMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "size", type: "uint256" }],
    name: "updateMaxBatchSize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "quantity", type: "uint256" }],
    name: "updateTeamNftStock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "wallet", type: "address" }],
    name: "updateTreasury",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistEndTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistOneMaxMint",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistOnePrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistOneTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistStatus",
    outputs: [
      { internalType: "bool", name: "minting", type: "bool" },
      { internalType: "string", name: "data", type: "string" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistTwoPrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistTwoTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawMoney",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];


// getbyatributes
// {"traits": [
//     {"trait_type":"BASE",
//     "value":"3RD-BASE"},
//     {"trait_type":"HANDS",
//     "value":"NOTHING"}
// ],
// "collection" : "Bolt"
// }
// getbyid
// {
// {id, collection}
// getall;
// {
//   collection;
// }