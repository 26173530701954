import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import badge from "../../assets/Badge.png";
import { CollectionName, mobileWidth } from "../../utils";
import "./style.scss"

const Gallery = () => {
  const URL = "https://dinger-back.herokuapp.com"
  var traits = []
  
  const imagesStub = [
    {
      url: "https://img.seadn.io/files/ad83dac8699f759e6d6ffa961c04e1e5.png?fit=max&w=600",
      title: "Image 1",
      id:"0"
    },
  ];

  const [isMobile, setisMobile] = useState(false);

  const [selectedIndex, setIndex] = useState(0);

  const [images, setImages] = useState(imagesStub);

  const [isLoading, setLoading] = useState(false);  

  const addTrait=(trait, value)=>{

    var val = {}
    val = {"trait_type": trait, "value":value}
    const index = traits.findIndex(item=> item.trait_type == trait)
    if(index> -1){      
      if(trait === value){
        traits = traits.splice(index, 1)
        
      }else{
        traits[index] = val
      }
    }else{
      traits.push(val)
    }

    console.log("traits", traits)
    getImagesByAttr()
  }

  const getImagesByAttr=async ()=>{
    setLoading(true)
    try{
    const res = await axios.post(`${URL}/getbyatributes`, {traits, "collection":CollectionName[selectedIndex].name.toUpperCase()});
    setImages([]);
    setImages(
      res.data.response.map((it) => ({ url: it.image, title: it.name, id:it.nftId }))
    );
    
    }catch(e){
      console.log("err", e);
    }
    setLoading(false)
  }
  const getByCollection = async()=>{
    setLoading(true)
    try{
    const res = await axios.post(`${URL}/getall`, {
      collection: CollectionName[selectedIndex].name.toUpperCase(),
    });
    
    setImages([])
    setImages(res.data.response.map((it) => ({ url: it.image, title: it.name, id:it.nftId })));
    
  }catch(e){
    console.log("err", e);
  }
  setLoading(false);

  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(()=> {
    getByCollection()
  },[selectedIndex])

  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width < mobileWidth) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  return (
    <div style={{ marginTop: "150px", padding: isMobile ? "0 15px" : "" }}>
      <p
        style={{
          position: "relative",
          display: "flex",
          fontFamily: "Fredericka the Great",
          fontSize: isMobile ? "36px" : "36px",
          color: "#a62f24",
          zIndex: "260",
          textAlign: "center",
          width: "100%",
          marginLeft: isMobile ? "35%" : "50%",
        }}
      >
        Gallery
        <img
          src={badge}
          alt=""
          style={{
            position: "absolute",
            top: isMobile ? "-80%" : "-80%",
            left: isMobile ? "-115px" : "-115px",
            width: "350px",
            zIndex: "-25",
          }}
        />
      </p>
      <Row
        style={{ marginTop: "100px", paddingInline: isMobile ? "5px" : "25px" }}
      >
        <Col lg={3}>
          <div className="d-flex flex-column mb-5">
            <Form.Select
              style={{
                backgroundColor: "#0000008f",
                border: "none",
                fontFamily: "Fredericka the Great",
                color: "white",
              }}
              defaultValue={CollectionName[0].name.toUpperCase()}
              onChange={(e) => {
                setIndex(
                  CollectionName.findIndex(
                    (item) => item.name === e.target.value.toLowerCase()
                  )
                );
                traits = [];
              }}
            >
              <option disabled style={{ color: "#ddcba3" }}>
                COLLECTION NAME
              </option>
              {CollectionName.map((it) => (
                <option>{it.name.toUpperCase()}</option>
              ))}
            </Form.Select>
            {Object.keys(CollectionName[selectedIndex].attributes).map(
              (element) => (
                <Form.Select
                  style={{
                    backgroundColor: "#0000008f",
                    border: "none",
                    fontFamily: "Fredericka the Great",
                    color:
                      traits.findIndex(
                        (item) => item.value == element.toUpperCase()
                      ) > -1
                        ? "red"
                        : "white",
                  }}
                  defaultValue={element.toUpperCase()}
                  onChange={(e) =>
                    addTrait(
                      element.toUpperCase(),
                      e.target.value.toUpperCase()
                    )
                  }
                >
                  <option style={{ color: "#ddcba3" }}>
                    {element.toUpperCase()}
                  </option>
                  {CollectionName[selectedIndex].attributes[element].map(
                    (it) => (
                      <option>{it}</option>
                    )
                  )}
                </Form.Select>
              )
            )}
            <Button
              style={{ backgroundColor: "#a62f24", border: "none" }}
              onClick={() => {
                getByCollection();
                setIndex(0);
              }}
            >
              Reset Filters
            </Button>
          </div>
        </Col>
        <Col lg={9}>
          <Row>
            {!isLoading ? (
              images.length > 0 ? (
                images.map((item) => (
                  <Col lg={4} md={6}>
                    <img
                      src={item.url}
                      alt=""
                      style={{ maxWidth: "100%" }}
                      onClick={() =>
                        window.open(
                          `https://opensea.io/assets/ethereum/${CollectionName[selectedIndex].contractAddress}/${parseInt(item.id)+1}`
                        )
                      }
                    />
                    <p
                      style={{
                        fontFamily: "Fredericka the Great",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "black",
                      }}
                    >
                      {item.title}
                    </p>
                  </Col>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>No Images found.</p>
              )
            ) : (
              <Spinner animation="border" />
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Gallery;
